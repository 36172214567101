import { useMemo } from 'react';
import { useParams } from 'react-router';

import Content from 'layout/Content';
import SEO from 'layout/SEO';
import { useResource } from 'components/providers/Resource';

import 'styles/article/admonitions.css';
import 'styles/article/github.css';

import MarkdownGuide from 'components/features/guide/MarkdownGuide';
import Application from 'layout/Application';
import { GuideCategoryType } from 'types/resource';
import GuideContent from 'components/features/guide/GuideContent';
import SectionList from 'components/features/guide/SectionList';
import Overview from 'components/features/guide/Overview';
import Glossary from 'components/features/guide/Glossary';
import GuideTitle from 'components/features/guide/GuideTitle';
import DnaBackground from 'public/dna.svg';

import cn from 'styles/classnames';

import Styles from './Guide.module.scss';

import { getGuideMetaDescription } from '../util';

const Guide = () => {
  const { category, guideId } =
    useParams<{ category: GuideCategoryType; guideId?: string }>();
  const { getGuide, getCategoryName } = useResource();

  const guide = useMemo(
    () => (guideId ? getGuide(category, guideId) : undefined),
    [getGuide, category, guideId],
  );
  return (
    <Application>
      <SEO
        title={guide ? guide.title : getCategoryName(category)}
        description={getGuideMetaDescription(
          guide?.title,
          getCategoryName(category),
        )}
      />
      <Content
        contentClassName={cn(
          'guides-content flex flex-col h-full max-w-none',
          Styles,
        )}
      >
        <div>
          <div className="bg-slate-100 relative h-50">
            <GuideTitle category={category} />
            <div className="absolute right-0 top-10">
              <img src={DnaBackground} alt="" />
            </div>
          </div>
        </div>
        <div
          className={cn('flex flex-row relative justify-evenly', {
            'justify-start': guideId === 'allguides',
          })}
        >
          <nav
            className={cn('mr-auto py-8 pl-4 bg-background-primary w-70', {
              'mr-0': guideId === 'allguides',
            })}
          >
            <SectionList category={category} />
          </nav>
          <div className="mr-auto">
            {guideId === 'glossary' ? (
              <Glossary category={category} />
            ) : guide && guideId ? (
              <>
                <GuideContent category={category} guideId={guideId} />
                {/* {category !== 'user' && (
                  <SuggestedGuide category={category} guide={guide} />
                )} */}
              </>
            ) : guideId === 'coherentpathbasics' ? (
              <MarkdownGuide
                title="Da Vinci Basics"
                category={category}
                guideFile="/resources/guides/user/cp-basics.md"
              />
            ) : guideId === 'accessingyouraccount' ? (
              <MarkdownGuide
                title="Accessing Your Account"
                category={category}
                guideFile="/resources/guides/user/campaigns/accessing-coherent-path.md"
              />
            ) : guideId === 'coherentpathtutorial' ? (
              <MarkdownGuide
                title="Da Vinci Tutorial"
                category={category}
                guideFile="/resources/guides/user/using-the-help-center.md"
              />
            ) : (
              <Overview category={category} />
            )}
          </div>
        </div>
      </Content>
    </Application>
  );
};
export default Guide;
